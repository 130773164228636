/*
 * @Author: your name
 * @Date: 2021-11-13 10:10:19
 * @LastEditTime: 2021-12-02 15:45:10
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\api\general-user\video-monitor.js
 */
import request from '@/utils/request.js';
/**
 * 获取列表
 * @returns {Promise}
 */
export function getList(data) {
  return request({
    url: '/traffic-construction/getFundPageAndDetail',
    method: 'post',
    data
  });
}
export function getProjectList(params) {
  return request({
    url: '/traffic-construction/getProjectDepartmentList',
    method: 'get',
    params
  });
}
export function postAdd(data) {
  return request({
    url: '/traffic-construction/addPlan',
    method: 'post',
    data
  });
}
export function postEdit(data) {
  return request({
    url: '/traffic-construction/editPlan',
    method: 'post',
    data
  });
}

export function postSortList(params) {
  return request({
    url: '/traffic-construction/configurationDetail/fundingDetail',
    method: 'get',
    params
  });
}
export function getFundDetail(params) {
  return request({
    url: '/traffic-construction/getFundDetail',
    method: 'get',
    params
  });
}
export function deletePlan(data) {
  return request({
    url: '/traffic-construction/deletePlan',
    method: 'post',
    data
  });
}
export function exportFundRecord(data) {
  return request({
    url: '/traffic-construction/exportFundRecord',
    method: 'post',
    data,
    responseType: 'blob'
  });
}

export function checkPlanApi(data) {
  return request({
    url: '/traffic-construction/checkPlan',
    method: 'post',
    data
  });
}
