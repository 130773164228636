<!--
 * @Author: your name
 * @Date: 2021-11-11 14:58:25
 * @LastEditTime: 2021-12-10 11:23:46
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\team-building\list-display\listDialog.vue
-->
<template>
  <el-dialog
    width="950px"
    :title="headerText"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleColse"
  >
    <el-form ref="form" :inline="true" label-width="140px" :model="paramsForm" :rules="rules" v-loading="dialogLoading">
      <el-form-item label="请选择所属项目部" prop="projectDepartment">
        <el-select
          v-model="paramsForm.projectDepartment"
          :disabled="isDisabled"
          placeholder="请选择"
          style="width:300px"
        >
          <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="paramsForm.name"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="规格型号" prop="model">
        <el-input
          v-model="paramsForm.model"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="数量" prop="num">
        <el-input
          v-model="paramsForm.num"
          maxlength="20"
          :disabled="isDisabled"
          @input="onChangeInput()"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="用途" prop="application">
        <el-input
          v-model="paramsForm.application"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="配置地点" prop="location">
        <el-input
          v-model="paramsForm.location"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="责任人" prop="responsible">
        <el-input
          v-model="paramsForm.responsible"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="责任人电话" prop="responsiblePhone">
        <el-input
          v-model="paramsForm.responsiblePhone"
          :disabled="isDisabled"
          maxlength="11"
          placeholder="请输入"
          oninput="value=value.replace(/[^\d]/g,'')"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          v-model="paramsForm.remark"
          :disabled="isDisabled"
          maxlength="20"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="deleteFunc" type="danger" v-if="isDisabled && $check('emergency-material_delete')"
        >删 除</el-button
      >
      <el-button @click="openDetail" type="primary" v-if="isDisabled && $check('emergency-material_edit')"
        >修 改</el-button
      >
      <el-button type="primary" v-if="!isDisabled" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getDetail, postAdd, postEdit, deletePlan } from '@/api/general-user/emergency-rescue.js';

export default {
  props: {
    headerText: {
      type: String
    },
    projectList: {
      type: Array
    }
  },
  data() {
    return {
      paramsForm: {
        projectDepartment: '',
        name: '',
        model: '',
        num: '',
        application: '',
        responsible: '',
        remark: '',
        location: ''
      },
      visible: false,
      rules: {
        projectDepartment: [{ required: true, message: '请选择所属项目部', trigger: 'change' }],
        name: [{ required: true, message: '请输入名称', trigger: 'change' }],
        model: [{ required: true, message: '请输入规格型号', trigger: 'change' }],
        num: [{ required: true, message: '请输入数量', trigger: 'change' }],
        application: [{ required: true, message: '请输入用途', trigger: 'change' }],
        location: [{ required: true, message: '请输入配置地点', trigger: 'change' }],
        responsible: [{ required: true, message: '请输入责任人', trigger: 'change' }],
        responsiblePhone: [
            { required: true, message: '请输入责任人电话', trigger: 'change' },
            { min: 11, max: 11, message: '请输入11位电话号码', trigger: 'change' }
          ],
      },
      detailId: '',
      isDisabled: false,
      dialogLoading: false,
      confirmLoading: false
    };
  },
  methods: {
    handleColse() {
      this.visible = false;
      this.$refs.form.resetFields();
      this.detailId = '';
      this.paramsForm = this.$options.data().paramsForm;
    },
    open(row) {
      this.visible = true;
      if (row?.id) {
        this.getDetail(row.id);
        this.isDisabled = true;
        for (const key in this.rules) {
          this.rules[key][0].required = false;
        }
      } else {
        for (const key in this.rules) {
          this.rules[key][0].required = true;
        }
        this.isDisabled = false;
      }
      // this.$nextTick(() => {
      //   this.paramsForm.orgId = orgId;
      //   this.paramsForm.orgName = orgName;
      //   // this.getGrantUserListData(orgId);
      // });
    },
    // 获取详情
    getDetail(id) {
      this.dialogLoading = true;
      getDetail({ id })
        .then(res => {
          if (!res.success) {
            this.$message.error(res.message);
            return;
          }
          this.paramsForm = res.data;
          this.detailId = res.data.id;
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },
    deleteFunc() {
      this.$confirm('请确认是否删除该应急设施', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deletePlan();
      });
    },
    //删除接口
    deletePlan() {
      deletePlan({ id: this.detailId }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功');
          this.handleColse();
          this.$emit('on-success');
        }
      });
    },
    openDetail() {
      this.dialogLoading = true;
      this.isDisabled = false;
      this.$emit('update:headerText', '修改计划');
      for (const key in this.rules) {
        this.rules[key][0].required = true;
      }
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
    },
    onChangeInput() {
      if (this.paramsForm.num < 0) {
        this.paramsForm.num = '';
      } else if (/[^\d^\.]+/g.test(this.paramsForm.num)) {
        this.paramsForm.num = '';
        // this.$message.error('只能输入数字');
      }
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        this.confirmLoading = true;
        if (!this.detailId) {
          postAdd(this.paramsForm)
            .then(res => {
              this.$message.success('新增成功！');
              this.handleColse();
              this.$emit('on-success');
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        } else {
          postEdit(this.paramsForm)
            .then(res => {
              this.$message.success('修改成功！');
              this.handleColse();
              this.$emit('on-success');
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.el-date-picker .el-picker-panel__content {
  height: auto;
}
</style>
