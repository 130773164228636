<!--
 * @Author: your name
 * @Date: 2021-11-09 10:49:22
 * @LastEditTime: 2021-12-08 14:52:41
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\safety-input\safety-plan\index.vue
-->
<template>
  <div>
    <el-form ref="queryFormRef" :inline="true" :model="queryForm" label-width="100px">
      <el-form-item prop="searchValue" label="模糊查询">
        <el-input v-model="queryForm.searchValue" placeholder="请输入名称/规格型号"></el-input>
      </el-form-item>
      <el-form-item label="所属项目部">
        <el-select v-model="queryForm.projectDepartmentList" multiple collapse-tags placeholder="请选择所属项目部">
          <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="btns">
      <el-button type="primary" icon="el-icon-plus" v-if="$check('emergency-material_add')" @click="handleAdd"
        >新增应急设施</el-button
      >
      <el-button
        @click="exportFunc"
        v-loading.fullscreen="exportLoading"
        element-loading-text="拼命导出中"
        element-loading-background="rgba(0, 0, 0, 0.1)"
        v-if="$check('emergency-material_export')"
        :disabled="btnDisabeld"
        >导出</el-button
      >
    </div>
    <el-table v-loading="tableLoading" :data="planList" ref="multipleTable" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="projectDepartmentName"
        label="所属项目部"
        show-overflow-tooltip
        sortable
      ></el-table-column>
      <el-table-column fixed align="center" prop="name" label="名称" sortable show-overflow-tooltip></el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="model"
        label="规格型号"
        sortable
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column fixed align="center" prop="num" label="数量" sortable show-overflow-tooltip></el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="application"
        label="用途"
        sortable
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="location"
        label="配置地点"
        sortable
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="responsible"
        label="责任人"
        sortable
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column fixed="right" align="center" width="200" label="操作">
        <template slot-scope="{ row }">
          <div>
            <span class="action-btn" @click="handleCetail(row)">查看详情</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :current-page="queryForm.current"
      :page-size="queryForm.size"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 50]"
      layout="total, sizes, prev, pager, next"
    ></el-pagination>

    <list-dialog ref="safetyRef" :headerText.sync="headerText" :projectList="projectList" @on-success="onSuccess" />
  </div>
</template>
<script>
import listDialog from './listDialog.vue';
import { getProjectList } from '@/api/general-user/safety-input.js';
import { getList, postMaterialExport } from '@/api/general-user/emergency-rescue.js';
import { exportFun } from '@/utils/export.js';

export default {
  components: {
    listDialog
  },
  data() {
    return {
      total: 0,
      queryForm: {
        current: 1,
        size: 10,
        projectDepartmentList: [],
        searchValue: ''
      },
      tableLoading: false,
      planList: [],
      projectList: [],
      multipleSelection: [],
      headerText: '新增应急设施',
      btnDisabeld: true,
      exportLoading: false
    };
  },
  created() {
    this.getList();
    this.getProjectList();
  },
  methods: {
    getProjectList() {
      getProjectList().then(res => {
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.projectList = res.data;
      });
    },
    handleSearch() {
      this.queryForm.current = 1;
      this.queryForm.size = 10;
      this.getList();
    },
    handleSizeChange(size) {
      this.queryForm.current = 1;
      this.queryForm.size = size;
      this.getList();
    },
    handleCurrentChange(current) {
      this.queryForm.current = current;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.forEach(item => {
        this.multipleSelection.push(item.id);
      });
      this.btnDisabeld = !Boolean(this.multipleSelection.length);
    },
    handleAdd() {
      this.headerText = '新增应急设施';
      this.$refs.safetyRef.open(undefined, 1);
    },
    handleReset() {
      this.$refs.queryFormRef.resetFields();
      this.queryForm = this.$options.data().queryForm;
      this.handleSearch();
    },
    exportFunc() {
      this.exportLoading = true;
      postMaterialExport({
        idList: this.multipleSelection
      })
        .then(res => {
          if (res.status === 200) {
            this.exportLoading = false;
            exportFun(res);
            this.$message({
              type: 'success',
              message: '导出数据成功'
            });
            this.$refs.multipleTable.clearSelection();
          }
        })
        .catch(() => {
          this.exportLoading = false;
          this.$refs.multipleTable.clearSelection();
        });
    },
    handleCetail(row) {
      this.headerText = '查看详情';
      this.$refs.safetyRef.open(row, 1);
    },
    getList() {
      this.tableLoading = true;
      getList(this.queryForm)
        .then(res => {
          this.total = res.data.total;
          this.planList = res.data.records;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    onSuccess() {
      this.handleSearch();
    }
  }
};
</script>
<style lang="less" scoped>
.btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // align-items: center;
}
</style>
