/*
 * @Author: your name
 * @Date: 2021-11-25 09:55:58
 * @LastEditTime: 2021-12-06 17:46:18
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\api\general-user\emergency-rescue.js
 */
import request from '@/utils/request.js';
/**
 * 列表
 * @returns {Promise}
 */
export function getList(data) {
  return request({
    url: '/traffic-construction/emergencyFacilities/list',
    method: 'post',
    data
  });
}
/**
 * 新增
 * @returns {Promise}
 */
export function postAdd(data) {
  return request({
    url: '/traffic-construction/emergencyFacilities/add',
    method: 'post',
    data
  });
}
/**
 * 详情
 * @returns {Promise}
 */
export function getDetail(params) {
  return request({
    url: '/traffic-construction/emergencyFacilities/getDetail',
    method: 'get',
    params
  });
}
/**
 * 编辑
 * @returns {Promise}
 */
export function postEdit(data) {
  return request({
    url: '/traffic-construction/emergencyFacilities/edit',
    method: 'post',
    data
  });
}
/**
 * 删除
 * @returns {Promise}
 */
export function deletePlan(data) {
  return request({
    url: '/traffic-construction/emergencyFacilities/delete',
    method: 'post',
    data
  });
}
/**
 * 演练列表
 * @returns {Promise}
 */
export function getDrillsList(data) {
  return request({
    url: '/traffic-construction/emergencyDrills/list',
    method: 'post',
    data
  });
}
/**
 * 配置下拉框
 * @returns {Promise}
 */
export function getConfigDetailList(params) {
  return request({
    url: '/traffic-construction/configurationDetail/configDetailList',
    method: 'get',
    params
  });
}
/**
 * 演练新增
 * @returns {Promise}
 */
export function postEmergencyDrills(data) {
  return request({
    url: '/traffic-construction/emergencyDrills/add',
    method: 'post',
    data
  });
}
/**
 * 演练详情
 * @returns {Promise}
 */
export function getEmergencyDetail(params) {
  return request({
    url: '/traffic-construction/emergencyDrills/getDetail',
    method: 'get',
    params
  });
}
/**
 * 演练编辑
 * @returns {Promise}
 */
export function getEmergencyEdit(data) {
  return request({
    url: '/traffic-construction/emergencyDrills/edit',
    method: 'post',
    data
  });
}
/**
 * 演练编辑
 * @returns {Promise}
 */
export function getEmergencyDelete(data) {
  return request({
    url: '/traffic-construction/emergencyDrills/delete',
    method: 'post',
    data
  });
}
/**
 * 图表
 * @returns {Promise}
 */
export function getPhotoData(data) {
  return request({
    url: '/traffic-construction/emergencyDrills/getPhotoData',
    method: 'post',
    data
  });
}
/**
 * 导出
 * @returns {Promise}
 */
export function postExport(data) {
  return request({
    url: '/traffic-construction/emergencyDrills/export',
    method: 'post',
    data,
    responseType: 'blob'
  });
}
/**
 * 导出
 * @returns {Promise}
 */
export function postMaterialExport(data) {
  return request({
    url: '/traffic-construction/emergencyFacilities/export',
    method: 'post',
    data,
    responseType: 'blob'
  });
}
